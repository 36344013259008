import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import changeLang from "../hooks/changeLang";
import { useLang } from "../../langProvider.js";

const Switcher = ({ paths, className }) => {
  const lang = useLang();

  const [showMenu, setShowMenu] = useState(false);

  const handleShowMenu = (e) => {
    e.preventDefault();

    if (!showMenu) {
      setShowMenu(true);
    }
  };

  const handleCloseMenu = useCallback(
    (e) => {
      // e.preventDefault();

      if (showMenu) {
        setShowMenu(false);
      }
    },
    [showMenu]
  );

  const handleLangChange = (lang) => {
    changeLang(paths, lang);
  };

  useEffect(() => {
    document.addEventListener("click", handleCloseMenu);

    return () => {
      document.removeEventListener("click", handleCloseMenu);
    };
  }, [handleCloseMenu, showMenu]);

  return (
    <div className={`relative text-center ${className}`}>
      <button className="capitalize md:uppercase text-gray-600" onClick={handleShowMenu}>
        <span className="uppercase md:inline-block lg:hidden">{lang}</span>
        <span className="hidden lg:inline-block">{lang === "en" ? "English" : "Español"}</span>
        <span className="icon-angle-down-l text-lg align-middle ml-1" />
      </button>
      {showMenu && (
        <div className="dropdown px-6 -ml-12 md:-ml-12 lg:-ml-6" style={{ top: 28, width: 135 }}>
          <button
            className="py-1 hover:font-semibold no-underline text-gray-700"
            // to={props.paths.en}
            onClick={() => handleLangChange("en")}
          >
            <span className="flex items-center">
              {lang === "en" && <span className="bg-brand-primary w-1 h-4 inline-block mr-1" />}
              ENGLISH
            </span>
          </button>
          <hr className="mb-2 mt-1" />
          <button
            className=" hover:font-semibold no-underline text-gray-700"
            // to={props.paths.es}
            onClick={() => handleLangChange("es")}
          >
            <span className="flex items-center">
              {lang === "es" && <span className="bg-brand-primary w-1 h-4 inline-block mr-1" />}
              ESPAÑOL
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

Switcher.propTypes = {
  paths: PropTypes.object.isRequired,
};

export default Switcher;
