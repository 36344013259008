export function isEmpty(pass) {
  return pass === "";
}

export function minLength(pass) {
  return pass.length < 8;
}

export function oneNumber(pass) {
  let re = /[0-9]/;
  return re.test(pass);
}

export function oneUppercase(pass) {
  let re = /[A-Z]/;
  return re.test(pass);
}

export function oneLowercase(pass) {
  let re = /[a-z]/;
  return re.test(pass);
}
