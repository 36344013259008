import React from "react";
import classNames from "classnames";

const InputError = ({ errors, field, className }) => {
  if (Object.keys(errors).length === 0) return null;

  const classes = classNames(
    "text-left text-red-700 text-xs mt-1 ml-3",
    className
  );

  return (
    <div className={classes}>{errors[field] && errors[field].message}</div>
  );
};

export default InputError;
