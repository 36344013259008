import React, { useState, useEffect, useCallback } from "react";
import changeLang from "~/hooks/changeLang";
import { useLang } from "../../langProvider.js";

const LangDropdown = ({ paths }) => {
  const lang = useLang();

  const [showLangSwither, setLangSwither] = useState(false);

  const handleLangChange = (lang) => {
    changeLang(paths, lang);
  };

  const openLangSwitcher = (e) => {
    e.preventDefault();

    if (!showLangSwither) setLangSwither(true);
  };

  const closeLangSwitcher = useCallback(
    (e) => {
      // e.preventDefault();

      if (showLangSwither) setLangSwither(false);
    },
    [showLangSwither]
  );

  useEffect(() => {
    document.addEventListener("click", closeLangSwitcher);

    return () => {
      document.removeEventListener("click", closeLangSwitcher);
    };
  }, [closeLangSwitcher, showLangSwither]);

  return (
    <div className="inline-block w-32 relative">
      {showLangSwither && (
        <div className="absolute bg-white w-full text-brand-link-gray -mt-24 rounded-brand py-2 text-center">
          <button className="py-1 hover:font-semibold no-underline text-gray-700" onClick={() => handleLangChange("en")} disabled={lang === "en"}>
            <span className="flex items-center">
              {lang === "en" && <span className="bg-brand-primary w-1 h-4 inline-block mr-1" />}
              ENGLISH
            </span>
          </button>
          <hr className="mb-2 mt-1" />
          <button className=" hover:font-semibold no-underline text-gray-700" onClick={() => handleLangChange("es")} disabled={lang === "es"}>
            <span className="flex items-center">
              {lang === "es" && <span className="bg-brand-primary w-1 h-4 inline-block mr-1" />}
              ESPAÑOL
            </span>
          </button>
        </div>
      )}
      <button className="inline-flex w-full items-center justify-between border border-gray-300 px-4 py-2 rounded-brand" onClick={openLangSwitcher}>
        <span className="icon-globe-americas" />
        <span>{lang === "en" ? "English" : "Español"}</span>
        <span className="icon-angle-up" />
      </button>
    </div>
  );
};

export default LangDropdown;
