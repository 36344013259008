import React from "react";
import PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { useForm } from "react-hook-form";
import validator from "validator";
import Input from "~/components/input";
import InputError from "~/components/inputError";
import SubmitSuccess from "~/components/auth/submitSuccess";
import ButtonStateful from "~/components/buttonStateful";
import Notification from "~/components/notification";

const ForgotForm = ({
  i18n,
  requested,
  error,
  loading,
  changeSection,
  handleRequestReset
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onBlur"
  });

  return (
    <div className="text-center text-base">
      <h2 className="my-8">
        <Trans>Forgot password</Trans>
      </h2>
      {requested ? (
        <SubmitSuccess>
          <Trans>
            If account exists, an email will be sent with further instructions.
          </Trans>
        </SubmitSuccess>
      ) : (
        <>
          <div className="text-sm mb-12 leading-loose">
            <Trans>Please enter your account email address</Trans>
            <br />
            <Trans>
              You will receive an email with a link to set up a new password
            </Trans>
          </div>

          <Notification type="error" className="text-center">
            {error}
          </Notification>

          <form className="text-sm mt-3" method="POST">
            <div className="mb-12">
              <Input
                type="email"
                placeholder={i18n._(t`Your Email address`)}
                {...register("email", {
                  required: true,
                  validate: (value) =>
                    validator.isEmail(value) ||
                    i18n._(t`Invalid e-mail address`)
                })}
                required
                disabled={loading}
                error={typeof errors["email"] !== "undefined"}
              />
              <InputError errors={errors} field="email" />
            </div>
            <div className="mb-12">
              <ButtonStateful
                type="submit"
                loading={loading}
                className="h-12 text-lg"
                onClick={handleSubmit(handleRequestReset)}
              >
                <Trans>Reset password</Trans>
              </ButtonStateful>
            </div>
          </form>
          <div className="mb-12">
            <button
              onClick={() => {
                changeSection("login");
              }}
              disabled={loading}
              className="text-sm text-brand-primary font-semibold hover:underline"
            >
              <Trans>Back to login</Trans>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

ForgotForm.defaultProps = {
  errorMsg: null,
  error: null
};

ForgotForm.propTypes = {
  i18n: PropTypes.object.isRequired,
  requested: PropTypes.bool.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  changeSection: PropTypes.func.isRequired,
  handleRequestReset: PropTypes.func.isRequired
};

export default withI18n()(ForgotForm);
