import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Link from "../link";

const CartWidget = ({ count, className }) => {
  const [animation, setAnimtaion] = useState("");
  const counter = useRef(count);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    if (count > 0 && counter.current < count) {
      if (isMounted.current) setAnimtaion("animate-bounce");
    }

    counter.current = count;

    if (isMounted.current) {
      setTimeout(function () {
        if (isMounted.current) setAnimtaion("");
      }, 2550);
    }

    return () => {
      isMounted.current = false;
      return false;
    };
  }, [count]);

  return (
    <Link to="/cart" className={`text-gray-400 relative ${className}`}>
      <span className="icon-shopping-cart" />
      {count > 0 && (
        <span
          className={`text-white font-bold absolute right-0 bg-brand-primary rounded-full w-5 h-5 text-center -m-2 -mr-3 leading-2 text-xs ${animation}`}
          style={{ paddingTop: 1 }}
        >
          {count}
        </span>
      )}
    </Link>
  );
};

const mapStateToProps = ({ cart }) => {
  return {
    count: cart.count
  };
};

export default connect(mapStateToProps)(CartWidget);
