import React, { useState } from "react";
import { Trans } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logOutUserAction } from "~/redux/actions/auth_actions";
import { logout } from "~/services/auth/logout";
import Bugsnag from "@bugsnag/js";
import ButtonStateful from "~/components/buttonStateful";
import navigate from "~/components/navigate";

const LogoutButton = ({ className, onLogOutUser, children, i18n }) => {
  const [loading, setLoadStatus] = useState(false);

  const handleLogOut = async (e) => {
    e.preventDefault();
    setLoadStatus(true);

    try {
      const { error, success } = await logout();

      if (error) {
        throw new Error(error.message);
      }

      if (success) {
        onLogOutUser();
        navigate(`/login?msg=logout`, i18n.language);
        return false;
      }
    } catch (e) {
      Bugsnag.notify(e);
    }

    setLoadStatus(false);
  };

  return (
    <ButtonStateful
      className={className}
      onClick={handleLogOut}
      loading={loading}
    >
      {children ? <>{children}</> : <Trans>Log Out</Trans>}
    </ButtonStateful>
  );
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLogOutUser: logOutUserAction,
    },
    dispatch
  );
};

export default withI18n()(connect(null, mapActionsToProps)(LogoutButton));
