import React from "react";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import Link from "./link";
import logo from "../images/vtutor_logo_reverse.svg";
import LangDropdown from "./langDropdown";
import SocialLinks from "./socialLinks";
import FooterSubscribe from "./footerSubscribe";

const FooterPage = ({ i18n, paths }) => {
  const lang = i18n.language;

  const blogUrl = lang === "es" ? "https://blog.vtutor.com/es/" : "https://blog.vtutor.com/";

  return (
    <div className="mt-4 text-center pb-8 px-6 xl:px-0 md:text-left md:ml-auto md:mr-auto lg:max-w-6xl">
      <Link to="/" className="mt-4 inline-block">
        <img src={logo} alt="vTutor" style={{ width: 108, height: 35 }} />
      </Link>
      <div className="flex flex-col md:flex-row mt-2">
        <FooterSubscribe />

        {/* lado derecho  */}
        <div className="flex justify-center md:justify-end flex-1 mt-6 md:mt-0 md:ml-4 text-left text-xs sm:text-sm">
          <div>
            <h3 className="mb-4 text-gray-700 text-sm md:text-base">vTutor</h3>
            <ul>
              <li>
                <Link to="/about-us" className="no-underline text-white">
                  <Trans>About us</Trans>
                </Link>
              </li>
              <li>
                <a href={blogUrl} className="no-underline text-white">
                  Blog
                </a>
              </li>
              <li>
                <a href="https://cursos.vtutor.com/afiliados" className="no-underline text-white" rel="nofollow">
                  <Trans>Affiliates</Trans>
                </a>
              </li>
              <li>
                <a href="mailto:support@vtutor.com" className="no-underline text-white" rel="nofollow">
                  <Trans>Contact us</Trans>
                </a>
              </li>
            </ul>
          </div>
          <div className="self-stretch py-12">
            <div className="h-full w-1 border-r border-gray-800 hidden xs:block xs:mx-2 lg:mx-12 xl:mx-5" />
          </div>
          <div>
            <h3 className="mb-4 text-gray-700 text-sm md:text-base">
              <Trans>For Students</Trans>
            </h3>
            <ul>
              <li>
                <Link to="/courses" className="no-underline text-white">
                  <Trans>All Courses</Trans>
                </Link>
              </li>
              <li>
                <a href="https://blog.vtutor.com/es/centro-de-ayuda/ayuda-a-estudiantes/" className="no-underline text-white">
                  <Trans>Student help center</Trans>
                </a>
              </li>
            </ul>
          </div>
          <div className="self-stretch py-12">
            <div className="h-full w-1 border-r border-gray-800 hidden xs:block xs:mx-2 lg:mx-12 xl:mx-5" />
          </div>
          <div>
            <h3 className="mb-4 text-gray-700 text-sm md:text-base">
              <Trans>For Tutors</Trans>
            </h3>
            <ul>
              <li>
                <a href="https://blog.vtutor.com/es/cursos-en-linea/ganar-dinero-cursos-online-2021/" className="no-underline text-white">
                  <Trans>Become a tutor</Trans>
                </a>
              </li>
              <li>
                <a href="https://blog.vtutor.com/es/centro-de-ayuda/ayuda-a-tutores/" className="no-underline text-white">
                  <Trans>Tutors help center</Trans>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex items-center w-full justify-center md:justify-end mt-6 md:-mt-1 lg:mt-4">
        <SocialLinks className="mr-0 pt-2 sm:mr-2 md:mr-4" />
        <LangDropdown paths={paths} />
      </div>
      <div className="text-center mt-8 text-sm text-gray-700">
        <Trans>Copyright</Trans> © {new Date().getFullYear()} vTutor Education LTD.
        <div className="mt-1 text-xs">
          <Link to="/terms" className="no-underline text-gray-700 hover:text-gray-700">
            <Trans>Terms & conditions</Trans>
          </Link>{" "}
          -{" "}
          <Link to="/privacy-policy" className="no-underline text-gray-700 hover:text-gray-700">
            <Trans>Privacy Policy</Trans>
          </Link>{" "}
          -{" "}
          <Link to="/cookie-policy" className="no-underline text-gray-700 hover:text-gray-700">
            <Trans>Cookie Policy</Trans>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withI18n()(FooterPage);
