import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { localizedSlug } from "../../utils/gatsby-node-helpers";

const SearchBox = withI18n()(
  ({ currentRefinement, isSearchStalled, refine, i18n, style }) => {
    const lng = i18n.language;

    const myAction = localizedSlug({
      isDefault: lng === "en" ? true : false,
      locale: lng,
      slug: "/courses",
    });

    return (
      <form noValidate action={myAction} role="search" method="GET">
        <div
          className="flex border border-gray-300 inputBox w-full rounded-full bg-white hover:shadow-focus"
          style={style}
        >
          <input
            type="search"
            value={currentRefinement}
            onChange={(event) => refine(event.currentTarget.value)}
            name="query"
            className="w-full p-2 px-0 ml-5 text-sm"
            placeholder={i18n._(t`What do you want to learn?`)}
            autoComplete="off"
          />
          <button
            style={{ width: 50 }}
            className="py-2 m-1 text-center text-brand-primary"
            //onClick={(e) => {e.preventDefault(); refine("")}}
            type="submit"
          >
            <span className="icon-search" />
          </button>
        </div>
        {/* {isSearchStalled ? "My search is stalled" : ""} */}
      </form>
    );
  }
);

const CustomSearchBox = connectSearchBox(SearchBox);

export default CustomSearchBox;
