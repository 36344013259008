import React from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import classNames from "classnames";
import CustomSearchBox from "./searchbox";
import CustomHits from "./hits";

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const searchClient = {
  search(requests) {
    if (requests.every(({ params }) => !params.query && params.query.trim())) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          processingTimeMS: 0,
        })),
      });
    }

    const newRequests = requests.map((request) => {
      // test for empty string and change request parameter: analytics
      if (!request.params.query || request.params.query.length === 0) {
        request.params.analytics = false;
      }
      return request;
    });

    return algoliaClient.search(newRequests);

    // return algoliaClient.search(requests);
  },
};

const Instant = (props) => {
  const classes = classNames("relative", props.className);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
    >
      <div className={classes}>
        <CustomSearchBox style={props.style} />
        <CustomHits />
      </div>
    </InstantSearch>
  );
};

export default Instant;
