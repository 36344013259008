import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import { connect } from "react-redux";
import Switcher from "./switcher";
import CartWidget from "./shoppingCart/cartWidget";
import Modal from "./auth/modal";
import ModalSearch from "./modalSearch";
import UserMenu from "./auth/userMenu";
import Button from "./button";
import { isClient, wpURL } from "~/utils/app-helpers";
import { ModalContext } from "~/providers/modalProvider";
import { useLang } from "~/../langProvider.js";
import loading from "../images/loading.svg";

const HeaderMenu = (props) => {
  const [section, setSection] = useState(null);
  const [logged, setLoginStatus] = useState(null);
  const [showModalRedirect, setVisibilityModalRedirect] = useState(false);
  const [modalStatus, manageModal] = useContext(ModalContext);
  const lang = useLang();

  useEffect(() => {
    if (isClient) document.body.classList.toggle("overflow-hidden", modalStatus.login);

    if (props.auth.email === null) setLoginStatus(false);
    else setLoginStatus(true);

    return () => {
      return false;
    };
  }, [modalStatus.login, props.auth.email]);

  const goMyCourses = (e) => {
    e.preventDefault();

    if (isClient) {
      setVisibilityModalRedirect(true);
      window.location.href = `${wpURL(lang)}/learner/my-purchased-courses/`;
    }
    return false;
  };

  const showModal = (section) => (e) => {
    e.preventDefault();

    manageModal({ login: true });
    setSection(section);
  };

  return (
    <div className="flex items-center flex-row-reverse mt-1  md:flex-row  md:text-xs">
      {showModalRedirect && (
        <div className="lightbox">
          <div className="flex justify-center h-full items-center">
            <div className="inline-block h-32 text-center">
              <h2 className="text-white">Cargando cursos</h2>
              <img src={loading} alt="loading" className="inline-block" />
            </div>
          </div>
        </div>
      )}

      {modalStatus.login && (
        <Modal
          hideModal={() => {
            manageModal({ login: false });
          }}
          section={section}
        />
      )}
      <ul className="flex text-base ml-1 sm:ml-3">
        <li className="sm:mr-4 mr-3 ml-2 md:hidden">
          <ModalSearch />
        </li>
        <li className="md:mr-2 md:hidden">
          <CartWidget />
        </li>
      </ul>
      <Switcher className="w-8 lg:w-20" paths={props.paths} />
      <div className="ml-4 border-l border-gray-200 hidden md:flex items-center">
        <span className="px-4 text-base">
          <CartWidget />
        </span>
        {logged === null ? (
          <div className="mock flex items-center space-x-4">
            <div className="w-24 h-8 bg-gray-200 rounded-brand"></div>
            <div className="w-24 h-8 bg-gray-200 rounded-brand"></div>
          </div>
        ) : (
          <>
            {!logged ? (
              <>
                <Button classType="reverse" className="uppercase mx-4 font-semibold" onClick={showModal("register")}>
                  <Trans>Sign up</Trans>
                </Button>
                <Button className="uppercase" onClick={showModal("login")}>
                  <Trans>Log in</Trans>
                </Button>
              </>
            ) : (
              <>
                <a href={`${wpURL(lang)}/notifications/`} className={`text-gray-400 px-2 text-lg mr-1`}>
                  <span className="icon-bell" />
                </a>
                <UserMenu className="mr-5 ml-2" />
                <Button className="uppercase" onClick={goMyCourses}>
                  <Trans>My Courses</Trans>
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

HeaderMenu.propTypes = {
  paths: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps)(HeaderMenu);
