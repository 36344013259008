export async function subscribeMailchimp(email) {
  let error = null;
  let result = null;

  try {
    const response = await fetch(
      `https://hooks.zapier.com/hooks/catch/3414276/olevlkl/silent/`,
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({ email }),
      }
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    result = await response.json();
  } catch (e) {
    error = e;
  }

  return { error, result };
}
