import React, { useState } from "react";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import validator from "validator";
import { useForm } from "react-hook-form";
import Bugsnag from "@bugsnag/js";
import InputError from "./inputError";
import { subscribeMailchimp } from "~/services/misc/subscribeMailchimp";

const FooterSubscribe = ({ i18n }) => {
  const {
    register,
    handleSubmit,
    reset,

    formState: {
      errors,
    },
  } = useForm({ mode: "onBlur" });
  const [success, setSuccessState] = useState(false);
  const [loading, setLoadingState] = useState(false);

  const submit = async (body, e) => {
    setLoadingState(true);

    try {
      const result = await subscribeMailchimp(body.subscribe2);

      if (result) {
        setSuccessState(true);
        reset();
      }
    } catch (e) {
      Bugsnag.notify(e);
      console.log(e);
    }

    setLoadingState(false);
  };

  return (
    <div className="flex-1">
      <span className="text-2xl font-bold">
        <Trans>Be the first to hear about our promotions!</Trans>
      </span>
      <p className="mt-2 mb-2 text-sm">
        <Trans>
          Subscribe to our mailing list and hear about our latest promotions and
          new courses.
        </Trans>
      </p>
      <form >
        <div className="bg-white rounded-brand flex justify-between mt-6 lg:max-w-md">
          <input
            type="email"
            {...register('subscribe2', {
              required: true,
              validate: (value) =>
                validator.isEmail(value) || i18n._(t`Invalid e-mail address`),
            })}
            className="my-2 ml-3 text-sm text-brand-link-gray w-full"
            placeholder={i18n._(t`Enter your email`)}
            autoComplete="off"
            disabled={loading} />
          <button
            type="submit"
            className="bg-brand-primary px-3 text-sm font-semibold"
            disabled={loading}
            style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
            onClick={handleSubmit(submit)}
          >
            <Trans>Subscribe</Trans>
          </button>
        </div>
      </form>
      <InputError errors={errors} field="subscribe2" className="text-lg" />
      {success && (
        <div className="text-white pt-2 text-lg font-semibold ml-3">
          <Trans>Confirmed, you are now signed up</Trans>
        </div>
      )}
    </div>
  );
};

export default withI18n()(FooterSubscribe);
