import React, { useEffect } from "react";
import classNames from "classnames";

const Notification = ({ type, children, className }) => {
  useEffect(() => {
    return () => {
      return false;
    };
  }, [children]);

  if (children === null) return null;

  let classes = classNames(
    "border rounded-brand mb-4 p-3 text-base ",
    className,
    {
      "bg-red-200 border-red-200 text-red-600": type === "error",
      "bg-green-200 border-green-200 text-green-600": type === "success",
    }
  );

  return <div className={classes}>{children}</div>;
};

export default Notification;
