import React from "react";
import { I18n } from "@lingui/react";
import categoriesList from "../locales/es/filters.json";

// here's a custom hook that is used to fetch data from an API
export default function categoriesTranslations(category) {
  return (
    <I18n>
      {({ i18n }) => {
        if (typeof category !== "undefined") {
          return i18n.language === "es" &&
            typeof categoriesList[category] !== "undefined"
            ? categoriesList[category].replace(/-/g, " ")
            : category.replace(/-/g, " ");
        } else {
          return null;
        }
      }}
    </I18n>
  );
}
