import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default function Button(props) {
  let { classType, className, children, ...rest } = { ...props };

  let classes = classNames(
    className,
    "btn w-full md:text-center whitespace-nowrap",
    {
      "btn-primary": classType === "primary",
      "btn-primary-reverse": classType === "reverse",
      "btn-default": classType === "default",
    }
  );

  return (
    <button className={classes} {...rest}>
      {children}
    </button>
  );
}

Button.defaultProps = {
  classType: "primary",
};

Button.propTypes = {
  classType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
