import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Trans } from "@lingui/macro";
import logo from "../images/vtutor_logo.svg";
import Footer from "./footer";
import HeaderMenu from "./headerMenu";
import Link from "./link";
import Instant from "./search/instant";
import MobileMenu from "./mobileMenu";
import PromoHeader from "./promoHeader";
import ProHeader from "./proHeader";

const Layout = (props) => {
  const {
    children,
    className,
    paths,
    style,
    hideFooter = false,
    hidePromoBar = false,
    footerclassName,
    id,
    hideWidgets = false,
    showSecure = false,
  } = props;
  const [mobMenuIsVisible, setMobileMenuVisivility] = useState(false);

  const handleMobMenuVisibility = () => {
    setMobileMenuVisivility(!mobMenuIsVisible);
  };

  const classes = classNames("main_wrapper", className, {});

  useEffect(() => {
    return () => {
      return false;
    };
  }, []);

  return (
    <div className={classes} style={style} id={id}>
      <div>
        {/* {<PromoHeader hidePromoBar={hidePromoBar} />} */}
        {hideWidgets === false && <MobileMenu paths={paths} visible={mobMenuIsVisible} setMobileMenuVisivility={setMobileMenuVisivility} />}
        <div className="w-full bg-white" style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}>
          <ProHeader />
          <div className="h-12 flex items-center justify-between relative px-4 md:px-6 xl:px-0 md:mr-auto md:ml-auto lg:max-w-6xl md:h-18">
            {hideWidgets === false && (
              <button className="flex-1 md:flex-none text-brand-primary text-left md:text-2xl md:mr-6" onClick={handleMobMenuVisibility}>
                <span className="icon-bars text-2xl" />
              </button>
            )}
            <Link to="/" className="flex-1 md:text-left md:w-32 w-full" style={{ maxWidth: 110 }}>
              <img src={logo} alt="vTutor" className="w-20 inline-block sm:w-22 md:-ml-1  md:w-26 lg:w-32" />
            </Link>
            {hideWidgets === false && (
              <div className="flex-1 flex items-center md:w-full justify-end">
                <div className="hidden md:flex items-center justify-center w-full lg:justify-end">
                  {/* <CatMenu /> */}
                  <Instant className="hidden md:inline-block md:w-full md:mx-2 lg:w-full lg:mx-4 xl:ml-6 " />
                </div>
                <HeaderMenu paths={paths} locale={props.locale} />
              </div>
            )}
            {showSecure && (
              <div>
                <span className="text-brand-link-gray mr-2 text-sm">
                  <Trans>Secured</Trans>
                </span>
                <span className="icon-lock-alt" />
              </div>
            )}
          </div>
        </div>
      </div>
      <>
        {children}
        {!hideFooter && (
          <div className={`bg-black text-white border-t-8 border-gray-900 ${footerclassName}`}>
            <Footer paths={paths} />
          </div>
        )}
      </>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  paths: PropTypes.object.isRequired,
};

export default Layout;
