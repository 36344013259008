import React from "react";
import { withI18n } from "@lingui/react";

const SocialLinks = ({ i18n, className }) => {
  const lang = i18n.language;

  const instagram = {
    es: "https://www.instagram.com/vtutor_es/",
    en: "https://www.instagram.com/vtutorcom",
  };

  const twitter = {
    es: "https://twitter.com/vtutor_es",
    en: "https://twitter.com/vtutorcom",
  };

  return (
    <div className={`inline-block ${className}`}>
      <a
        href={instagram[lang]}
        className="text-white mr-1 sm:mr-2 inline-block text-3xl xs:text-4xl"
        // target="_blank"
        rel="noopener noreferrer"
      >
        <span className="icon-instagram-square" />
      </a>
      <a
        href="https://www.facebook.com/vtutorcom/"
        className="text-white mr-1 sm:mr-2 inline-block text-3xl xs:text-4xl"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="icon-facebook-square" />
      </a>
      <a
        href={twitter[lang]}
        className="text-white mr-1 sm:mr-2 inline-block text-3xl xs:text-4xl"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="icon-twitter-square" />
      </a>
      <a
        href="https://medium.com/@vtutor"
        className="text-white mr-1 sm:mr-2 inline-block text-3xl xs:text-4xl"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="icon-medium" />
      </a>
      <a
        href="https://www.youtube.com/channel/UCdQqWB0-dwT-7F2LMnhuLmA"
        className="text-white mr-1 sm:mr-2 inline-block text-3xl xs:text-4xl"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="icon-youtube-square" />
      </a>
    </div>
  );
};

export default withI18n()(SocialLinks);
