import React from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import facebooklogo from "~/images/facebook.svg";
import googlelogo from "~/images/google.svg";

const LoginSocial = (props) => {
  if (process.env.GATSBY_SOCIAL_LOGIN_ENABLE === "false") {
    return null;
  }

  const handleSocialLogin = (socialPlatform) => (e) => {
    e.preventDefault();

    alert(socialPlatform);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-3 md:mb-4">
        <hr className="flex-1" />
        <span className="mx-4">
          <Trans>OR</Trans>
        </span>
        <hr className="flex-1" />
      </div>
      <div className="flex flex-col justify-between mb-2 text-sm md:text-xxs md:flex-row">
        <button
          className="rounded-brand btn bg-blue-700 text-white px-1 py-2 h-10 mb-2 md:w-40"
          onClick={handleSocialLogin("face")}
          disabled={props.disableForm}
        >
          <img
            src={facebooklogo}
            alt="facebook"
            className="inline-block mr-2"
          />{" "}
          <span className="align-middle">
            <Trans>Continue with Facebook</Trans>
          </span>
        </button>
        <button
          className="rounded-brand btn bg-blue-600 text-white px-1 py-2 h-10 md:w-40"
          onClick={handleSocialLogin("google")}
          disabled={props.disableForm}
        >
          <img src={googlelogo} alt="google" className="inline-block mr-2" />{" "}
          <span className="align-middle">
            <Trans>Continue with Google</Trans>
          </span>
        </button>
      </div>
    </>
  );
};

LoginSocial.propTypes = {
  disableForm: PropTypes.bool.isRequired,
};

export default LoginSocial;
