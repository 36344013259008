import { navigate as GatsbyNavigate } from "gatsby";
import { localizedSlug } from "../utils/gatsby-node-helpers";
import { isClient } from "~/utils/app-helpers";

export default function navigate(to, lang, options = {}) {
  const path = localizedSlug({
    isDefault: lang === "en" ? true : false,
    locale: lang,
    slug: to,
  });

  if (isClient) GatsbyNavigate(path, options);
}
