import React, { useState, useEffect } from "react";
import { Trans } from "@lingui/macro";
import classNames from "classnames";
import { isClient } from "~/utils/app-helpers";

const PromoHeader = (props) => {
  const [visible, setVisibility] = useState(false);

  const hide = (e) => {
    e.preventDefault();

    if (isClient) {
      sessionStorage.setItem("vtPromoBar", "false");
      setVisibility(false);
      // props.isVisible(false);
    }
  };

  const classes = classNames("text-white w-full flex items-center justify-center font-semibold text-center flex-col md:flex-row md:space-x-1", {
    hidden: !visible || props.hidePromoBar === true,
  });

  const { isVisible } = props;

  useEffect(() => {
    if (isClient) {
      const isVisibleStorage = sessionStorage.getItem("vtPromoBar");

      if (isVisibleStorage === null) {
        setVisibility(true);
      } else {
        setVisibility(JSON.parse(isVisibleStorage));
      }
    } else {
      setVisibility(true);
    }

    isVisible(visible);
    return () => {
      return false;
    };
  }, [isVisible, visible]);

  return (
    <div className={classes} style={{ backgroundColor: "#954AD8" }} id="promo-bar">
      <button className="absolute right-0 mr-6 md:mr-6" onClick={hide}>
        <span className="icon icon-times"></span>
      </button>
      <span id="promo-content"></span>
    </div>
  );
};

PromoHeader.defaultProps = {
  isVisible: () => {
    return false;
  },
};

export default PromoHeader;
