import React, { useRef, useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import LogoutButton from "~/components/auth/logoutButton";
import UserMenuLink from "./userMenuLink";
import { wpURL } from "~/utils/app-helpers";

export const UserMenu = ({ auth, className, i18n, show = false }) => {
  const userMenu = useRef(null);
  const [showMenu, setShowMenu] = useState(show);

  const avatar = Boolean(auth.avatar_url)
    ? auth.avatar_url
    : `https://eu.ui-avatars.com/api/?name=${
        Boolean(auth.full_name) ? auth.full_name : auth.email
      }&rounded=true&background=bbbbbb&format=svg&color=ffffff&bold=true`;

  const classes = classNames("relative w-8", className);

  const handleOpenUserMenu = (e) => {
    e.preventDefault();

    if (!showMenu) {
      setShowMenu(true);
    }
  };

  const handleCloseMenu = useCallback(
    (e) => {
      // e.preventDefault();

      if (userMenu.current && !userMenu.current.contains(e.target) && showMenu)
        setShowMenu(false);
    },
    [showMenu]
  );

  useEffect(() => {
    document.addEventListener("click", handleCloseMenu);

    return () => {
      document.removeEventListener("click", handleCloseMenu);
    };
  }, [handleCloseMenu, showMenu]);

  return (
    <div className={classes}>
      <div
        className="w-8 h-8 rounded-full shadow cursor-pointer inline-block relative"
        style={{
          backgroundColor: "#bbbbbb",
          backgroundImage: `URL("${avatar}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
        onClick={handleOpenUserMenu}
      >
        {auth.full_name === "" && (
          <div
            className="absolute h-3 w-3 rounded-full bg-red-600"
            style={{ top: 0, right: -4 }}
          />
        )}
      </div>
      {showMenu && (
        <div
          ref={userMenu}
          className="dropdown px-6 mt-2 text-sm"
          style={{ width: "18rem", left: "-8rem", maxHeight: "440px" }}
        >
          <ul className="list-bordered text-left space-y-3">
            <li className="text-center pb-3">
              {auth.email !== auth.full_name && (
                <div className="font-bold">{auth.full_name}</div>
              )}
              <div className="text-brand-primary font-semibold text-sm">
                {auth.email}
              </div>
            </li>
            <li>
              <UserMenuLink
                to={`${wpURL(i18n.language)}/learner/my-purchased-courses/`}
                text={`${i18n._(t`My Courses`)}`}
                badge={auth.coursesCounter > 0 ? auth.coursesCounter : ""}
              />
            </li>
            <li>
              <UserMenuLink
                to={`${wpURL(i18n.language)}/profile/`}
                text={i18n._(t`Profile`)}
                alert={auth.full_name === ""}
              />
            </li>
            {!auth.is_tutor && (
              <li>
                <UserMenuLink
                  to="https://blog.vtutor.com/es/cursos-en-linea/ganar-dinero-cursos-online-2021/"
                  text={i18n._(t`Become a tutor`)}
                />
              </li>
            )}
            {auth.is_tutor && (
              <>
                <li className="uppercase font-bold pb-2">For Tutors</li>
                <li>
                  <UserMenuLink
                    to={`${wpURL(i18n.language)}/tutor/instructor-dashboard/`}
                    text={i18n._(t`Dashboard`)}
                  />
                </li>
                <li>
                  <UserMenuLink
                    to={`${wpURL(i18n.language)}/tutor/revenue-report/`}
                    text={i18n._(t`Revenue Report`)}
                  />
                </li>
                <li>
                  <UserMenuLink
                    to={`${wpURL(i18n.language)}/tutor/create-course/`}
                    text={i18n._(t`Create a course`)}
                  />
                </li>
              </>
            )}
            <li
              className="flex justify-between space-x-2"
              style={{ height: 36 }}
            >
              <a
                href="https://support.vtutor.com/"
                className="btn btn-primary-reverse uppercase"
              >
                <Trans>Help</Trans>
              </a>
              <LogoutButton className="flex-1 uppercase">
                <Trans>Log out</Trans>
              </LogoutButton>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    auth
  };
};

export default withI18n()(connect(mapStateToProps)(UserMenu));
