import React, { useState } from "react";
import PropTypes from "prop-types";

import ModalWrapper from "../modalWrapper";
import LoginForm from "./loginForm";
import Forgot from "./forgot/";
import RegisterForm from "./registerForm";

const Modal = (props) => {
  const [section, setSection] = useState(props.section);

  const renderSection = (hideModal) => {
    switch (section) {
      case "login":
        return <LoginForm changeSection={setSection} hideModal={hideModal} />;
      case "forgot":
        return <Forgot changeSection={setSection} />;
      case "register":
        return <RegisterForm changeSection={setSection} />;
      default:
        return <LoginForm changeSection={setSection} hideModal={hideModal} />;
    }
  };

  return (
    <ModalWrapper width={410} hideModal={props.hideModal}>
      {renderSection(props.hideModal)}
    </ModalWrapper>
  );
};

Modal.propTypes = {
  // methods
  hideModal: PropTypes.func.isRequired
};

Modal.defaultProps = {
  section: "login"
};

export default Modal;
