export async function logout() {
  let error = null;

  try {
    const response = await fetch(`${process.env.GATSBY_AUTH_URL}/logout`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return await response.json();
  } catch (e) {
    error = e;
  }

  return { error };
}
