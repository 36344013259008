export async function login(email, password) {
  let error = null;

  try {
    const response = await fetch(`${process.env.GATSBY_AUTH_URL}/login`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: String(password),
      }),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return await response.json();
  } catch (e) {
    error = e;
  }

  return { error };
}
