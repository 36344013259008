import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Input = React.forwardRef((props, ref) => {
  const { type, className, placeholder, dynamicPlaceholder, error, ...rest } = props;

  const [fieldType, setFieldType] = useState(() => {
    return type === "showHide" ? "password" : type;
  });

  const [showPassword, setShowPassword] = useState(true);

  // useEffect(() => {
  //   console.log(error);
  //   return () => {
  //     return false;
  //   }; //clean
  // }, [error, fieldType]);

  let showPasswordClass = classNames("text-gray-400 text-lg ml-1 absolute right-0 mr-3", {
    "icon-eye": showPassword,
    "icon-eye-slash": !showPassword,
    "text-red-700": error,
  });

  const togglePassword = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowPassword(!showPassword);
    let newType = fieldType === "password" ? "text" : "password";
    setFieldType(newType);
  };

  let passwordClasses = null;

  if (fieldType === "showHide") {
    passwordClasses = "w-11/12";
  } else {
    passwordClasses = "w-full";
  }

  const wrapperClasses = classNames("border transition duration-100 ease-in p-3 rounded-brand text-left", className, {
    floating: dynamicPlaceholder,
    "border-gray-300 focus-within:border-gray-500": !error,
    "border-red-500": error,
  });

  return (
    <div className={wrapperClasses}>
      <input
        ref={ref}
        type={fieldType}
        className={`bg-transparent border-none text-lg ${passwordClasses} ${dynamicPlaceholder && "floating__input"} ${error && "text-red-700"}`}
        placeholder={placeholder}
        {...rest}
      />

      {dynamicPlaceholder && (
        <label className={`floating__label text-lg ${error && "error"}`} data-content={placeholder}>
          <span className="hidden">{placeholder}</span>
        </label>
      )}

      {type === "showHide" && (
        <button type="button" className={showPasswordClass} onClick={togglePassword} style={{ marginTop: 1 }} disabled={props.disabled} />
      )}
    </div>
  );
});

Input.propTypes = {
  // methods
  dynamicPlaceholder: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

Input.defaultProps = {
  error: false,
  placeholder: "",
  dynamicPlaceholder: true,
};

export default Input;
