import React from "react";
import PropTypes from "prop-types";

const SubmitSuccess = ({ children }) => {
  return (
    <div className="h-48 flex items-center justify-center">
      <div className="text-center">
        <div className="w-24 inline-block mb-4">
          <Image />
        </div>
        <div className="text-xl">{children}</div>
      </div>
    </div>
  );
};

SubmitSuccess.propTypes = {
  children: PropTypes.element.isRequired,
};

export const Image = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.2 130.2"
    >
      <circle
        className="path circle"
        fill="none"
        stroke="#73AF55"
        strokeWidth="6"
        strokeMiterlimit="10"
        cx="65.1"
        cy="65.1"
        r="62.1"
      />
      <polyline
        className="path check"
        fill="none"
        stroke="#73AF55"
        strokeWidth="6"
        strokeLinecap="round"
        strokeMiterlimit="10"
        points="100.2,40.2 51.5,88.8 29.8,67.5 "
      />
    </svg>
  );
};

export default SubmitSuccess;
