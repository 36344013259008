import React from "react";

const UserMenuLink = ({ to, text, badge = "", alert = false }) => {
  const classes =
    "font-semibold hover:text-brand-primary no-underline text-gray-600 uppercase w-full mb-2 flex items-center space-x-2 relative";

  return (
    <a href={to} className={classes}>
      <span className="relative">
        {text}
        {alert && (
          <div
            className="absolute h-3 w-3 rounded-full bg-red-600"
            style={{ right: -17, top: 4 }}
          />
        )}
      </span>{" "}
      {badge !== "" && <span>({badge})</span>}
    </a>
  );
};

export default UserMenuLink;
