import React, { useState } from "react";
import PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import Bugsnag from "@bugsnag/js";
import { requestResetPassword } from "~/services/auth/requestResetPass";
import ForgotForm from "~/components/auth/forgot/forgotForm";

const Forgot = ({ changeSection, i18n, errorMsg }) => {
  const [requested, setRequest] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(errorMsg);

  const handleRequestReset = async (body) => {
    setLoading(true);
    setError(null);

    try {
      const { error, data, success } = await requestResetPassword(
        body.email,
        i18n.language
      );

      if (error) {
        throw new Error(error.message);
      }

      if (success) {
        setRequest(true);
      } else {
        if (data.code === 500) throw new Error(data.message);
      }
    } catch (e) {
      Bugsnag.notify(e);
      setError(
        <Trans>
          Something happened, please refresh the window and try again.
        </Trans>
      );
    }

    setLoading(false);
  };

  return (
    <ForgotForm
      {...{
        requested,
        error,
        loading,
        changeSection,
        handleRequestReset
      }}
    />
  );
};

Forgot.defaultProps = {
  errorMsg: null
};

Forgot.propTypes = {
  changeSection: PropTypes.func.isRequired
};

export default withI18n()(Forgot);
