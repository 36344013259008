import React from "react";

const ProHeader = (props) => {
  return (
    <div
      className="text-center w-full"
      style={{
        backgroundColor: "#3B235E",
        // backgroundImage: "url(/images/cintillo-bg.png)",
        // backgroundPosition: "center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
        // height: 72,
      }}
    >
      <a href="https://cursos.vtutor.com/pro?utm_source=headvt">
        <img src="/images/banner-pro.png" alt="Pro membership" className="inline-block md:mr-auto md:ml-auto lg:max-w-6xl" />
      </a>
    </div>
  );
};

export default ProHeader;
