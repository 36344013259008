import React from "react";
import { Trans } from "@lingui/macro";
import Link from "./link";
import categoriesTranslations from "../hooks/categoriesTranslations";
import list from "../locales/es/filters.json";

const CatList = ({ limit = 19 }) => {
  let categories2 = Object.keys(list);

  if (limit !== null) {
    categories2 = categories2.slice(0, limit);
  }

  return (
    <>
      {categories2.map((val, idx) => {
        return (
          <Link
            key={`tcat_${idx}`}
            to={`/courses/${val}`}
            className="w-full capitalize flex items-center space-x-2 whitespace-nowrap text-brand-link-gray space-y-1 px-3 hover:text-gray-900"
          >
            <span className={`icon-cat-${val} text-brand-link-gray`} />
            <span>{categoriesTranslations(val)}</span>
          </Link>
        );
      })}
      <Link
        to={`/courses/`}
        className="w-full capitalize flex items-center space-x-2 whitespace-nowrap text-brand-link-gray space-y-1 px-3 hover:text-gray-900"
      >
        <span className={`icon-cat-all text-brand-link-gray`} />
        <span>
          <Trans>All Courses</Trans>
        </span>
      </Link>
    </>
  );
};

export default CatList;
