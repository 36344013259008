import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Trans } from "@lingui/macro";
import { connect } from "react-redux";
import Link from "./link";
import logo from "../images/vtutor_logo.svg";
import CatList from "./catList";
import Button from "./button";
import LogoutButton from "~/components/auth/logoutButton";
import { useLang } from "~/../langProvider.js";
import { isClient, wpURL } from "~/utils/app-helpers";
import loading from "../images/loading.svg";

export const MobileMenu = ({ paths, visible, setMenuIsVisible, setMobileMenuVisivility, auth }) => {
  const [isVisible, setVisible] = useState(visible);
  const [logged, setLoginStatus] = useState(null);
  const lang = useLang();

  useEffect(() => {
    setVisible(visible);

    if (auth.email === null) setLoginStatus(false);
    else setLoginStatus(true);

    if (typeof window !== "undefined") document.body.classList.toggle("overflow-hidden", visible);

    return () => {
      return false;
    };
  }, [visible, auth]);

  const overlay_class = classNames("overlayMenu", {
    show: isVisible,
  });

  const menu_class = classNames("cbp-spmenu cbp-spmenu-vertical cbp-spmenu-left", {
    "cbp-spmenu-open": isVisible,
  });

  const avatar = Boolean(auth.avatar_url)
    ? auth.avatar_url
    : `https://eu.ui-avatars.com/api/?name=${
        Boolean(auth.full_name) ? auth.full_name : auth.email
      }&rounded=true&background=bbbbbb&format=svg&color=ffffff&bold=true`;

  const [tutorView, setTutorView] = useState(false);

  const [showModalRedirect, setVisibilityModalRedirect] = useState(false);

  const goMyCourses = (e) => {
    e.preventDefault();

    if (isClient) {
      setVisibilityModalRedirect(true);
      window.location.href = `${wpURL(lang)}/learner/my-purchased-courses/`;
    }
    return false;
  };

  return (
    <>
      {showModalRedirect && (
        <div className="lightbox">
          <div className="flex justify-center h-full items-center">
            <div className="inline-block h-32 text-center">
              <h2 className="text-white">Cargando cursos</h2>
              <img src={loading} alt="loading" className="inline-block" />
            </div>
          </div>
        </div>
      )}
      <div className={overlay_class} onClick={() => setMobileMenuVisivility(false)} />
      <div className={menu_class}>
        <div className="px-2 overflow-y-auto bg-white h-full pb-12">
          {!logged ? (
            <div className="flex items-center pt-3 justify-center w-full">
              <Link to="/" className="w-26 inline-block ml-2">
                <img src={logo} alt="vTutor" style={{ width: 112, height: 36 }} />
              </Link>
            </div>
          ) : (
            <div className="flex items-center mt-1 justify-between">
              <div className="w-12 h-12 rounded-full shadow ml-2 hidden sm:block" style={{ backgroundColor: "#bbbbbb" }}>
                <img src={avatar} alt={auth.full_name} />
              </div>
              <div className="p-2 ml-2 flex-1 overflow-hidden">
                <div className="whitespace-nowrap">
                  <div className="font-bold truncate">{auth.full_name ? auth.full_name : auth.email}</div>
                  <Trans>Welcome back!</Trans>
                </div>
              </div>
              <button className="text-2xl mr-2" onClick={() => setMobileMenuVisivility(false)}>
                <span className="icon-times" />
              </button>
            </div>
          )}
          <div className="mx-2">
            <div className="border-b border-gray-300 pb-2 mt-4">
              <div className="flex justify-between items-center text-center space-x-2 mt-4">
                {!logged ? (
                  <>
                    <Link to="/register" className="w-full block btn btn-primary-reverse whitespace-nowrap px-3">
                      <Trans>Sign up</Trans>
                    </Link>
                    <Link to="/login" className="w-full block btn btn-primary px-3">
                      <Trans>Log in</Trans>
                    </Link>
                  </>
                ) : tutorView ? (
                  <a href={`${wpURL(lang)}/tutor/instructor-dashboard/`} className="w-full block btn bg-black text-white whitespace-nowrap px-3">
                    <Trans>Dashboard</Trans>
                  </a>
                ) : (
                  <>
                    <a href={`${wpURL(lang)}/profile/`} className="w-full block btn btn-primary-reverse whitespace-nowrap px-3">
                      <Trans>Profile</Trans>
                    </a>
                    <Button onClick={goMyCourses} className="w-full block btn btn-primary px-3 whitespace-nowrap">
                      <Trans>My courses</Trans>
                    </Button>
                  </>
                )}
              </div>
            </div>
            <div className="w-full pt-4 border-b border-gray-300 pb-3">
              {!tutorView ? (
                <>
                  <h3 className="mb-1">
                    <Trans>Categories</Trans>
                  </h3>
                  <CatList limit={10} />
                </>
              ) : (
                <>
                  <ul className="ml-4">
                    <li className="">
                      <TutorLink icon="icon-edit" to={`${wpURL(lang)}/tutor/create-course/`}>
                        <Trans>Create course</Trans>
                      </TutorLink>
                    </li>
                    <li className="">
                      <TutorLink icon="icon-file-invoice-dollar" to={`${wpURL(lang)}/tutor/revenue-report/`}>
                        <Trans>Revenue report</Trans>
                      </TutorLink>
                    </li>
                    <li className="">
                      <TutorLink icon="icon-rocket-launch" to={`${wpURL(lang)}/tutor/instructor-dashboard/?page=performance`}>
                        <Trans>Performance</Trans>
                      </TutorLink>
                    </li>
                    <li className="">
                      <TutorLink icon="icon-bell1" to={`${wpURL(lang)}/notifications/`}>
                        <Trans>Notifications</Trans>
                      </TutorLink>
                    </li>
                    <li className="">
                      <TutorLink icon="icon-user" to={`${wpURL(lang)}/profile/`}>
                        <Trans>Profile</Trans>
                      </TutorLink>
                    </li>
                  </ul>
                </>
              )}
            </div>
            {logged && auth.is_tutor && (
              <div className="w-full pt-4 border-b border-gray-300 pb-3">
                {tutorView ? (
                  <button className="w-full block btn text-black border border-black whitespace-nowrap px-3" onClick={() => setTutorView(false)}>
                    <Trans>Switch to student view</Trans>
                  </button>
                ) : (
                  <button className="w-full block btn btn-primary-reverse whitespace-nowrap px-3" onClick={() => setTutorView(true)}>
                    <Trans>Switch to tutor view</Trans>
                  </button>
                )}
              </div>
            )}
            <div className="w-full pt-3">
              <ul className="">
                <li className="mb-2">
                  <a
                    href="https://static.zdassets.com/web_widget/latest/liveChat.html?v=2#key=vtutor.zendesk.com"
                    className="text-lg no-underline text-gray-900 font-medium w-full block"
                  >
                    <span className="icon-comment-alt-smile mr-3" />
                    <Trans>Live chat</Trans>
                  </a>
                </li>
                <li className="">
                  <a href="https://support.vtutor.com/" className="text-lg no-underline text-gray-900 font-medium w-full block">
                    <span className="icon-info-circle mr-3" />
                    <Trans>Help center</Trans>
                  </a>
                </li>
              </ul>
            </div>
            {!tutorView && (
              <div className="w-full pt-4">
                <ul className="border-t border-gray-300 pt-3">
                  <li className="">
                    <a
                      href="https://blog.vtutor.com/es/cursos-en-linea/ganar-dinero-cursos-online-2021/"
                      className="text-lg no-underline text-gray-900 font-medium w-full block"
                    >
                      <span className="icon-video-plus mr-3" />
                      <Trans>Create a course</Trans>
                    </a>
                  </li>
                </ul>
              </div>
            )}
            {logged && (
              <div className="w-full pt-4">
                <ul className="border-t border-gray-300 pt-3">
                  <li className="">
                    <LogoutButton className="uppercase">
                      <div className="flex items-center space-x-2 justify-center">
                        <span className="icon-sign-out" />
                        <span>
                          <Trans>Log out</Trans>
                        </span>
                      </div>
                    </LogoutButton>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const TutorLink = ({ children, icon, to }) => {
  return (
    <a href={to} className="text-lg no-underline text-gray-700 font-medium w-full text-left flex items-center mb-2 hover:text-gray-900">
      <span className={`${icon} mr-3 w-4`} />
      <span className="text-brand-primary">{children}</span>
    </a>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps)(MobileMenu);
