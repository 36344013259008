import React from "react";
import PropTypes from "prop-types";

const ModalWrapper = (props) => {
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget && !props.disableUserHide)
      props.hideModal();
  };

  const onOk = () => {
    props.onOk();
    props.hideModal();
  };

  const okButton = props.showOk ? (
    <button onClick={onOk} disabled={props.okDisabled}>
      {props.okText}
    </button>
  ) : null;

  return (
    <div className="fixed z-50 left-0 top-0 h-screen w-screen">
      <div
        className={`box relative ${props.className} ${
          props.fullRounded ? "rounded-full" : "rounded-brand"
        }`}
        style={{ width: props.width, height: props.height }}
      >
        {!props.disableUserHide && (
          <button
            onClick={onOk}
            className="absolute right-0 py-3 text-white"
            style={{ fontSize: "2rem", marginTop: -75 }}
          >
            <span className="icon-times" />
          </button>
        )}
        {props.children}
        {okButton}
      </div>
      <div className="lightbox" onClick={handleBackgroundClick} />
    </div>
  );
};

ModalWrapper.propTypes = {
  // props
  showOk: PropTypes.bool,
  okText: PropTypes.string,
  okDisabled: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,

  // methods
  hideModal: PropTypes.func,
  onOk: PropTypes.func,
};

ModalWrapper.defaultProps = {
  showOk: false,
  okText: "OK",
  okDisabled: false,
  disableUserHide: false,
  width: "100%",
  height: "auto",
  onOk: () => {},
  fullRounded: false,
};

export default ModalWrapper;
