import React from "react";
import { Trans } from "@lingui/macro";
import {
  isEmpty,
  minLength,
  oneNumber,
  oneUppercase,
  oneLowercase,
} from "~/utils/password-validator";

// const passwordValidator = (props) => {
//   const pass = String(props.pass);
//   const confirmPass = String(props.confirmPass);

//   if (!isEmpty(pass)) {
//     return <Trans>The New Password field is required.</Trans>;
//   }

//   if (!minLength(pass)) {
//     return <Trans>The New Password must be grater than 8 characters.</Trans>;
//   }

//   if (!oneNumber(pass)) {
//     return <Trans>The password must contain at least one number (0-9)</Trans>;
//   }

//   if (!oneLowercase(pass)) {
//     return <Trans>The password must contain at least one lowercase letter (a-z)</Trans>;
//   }

//   if (!oneUppercase(pass)) {
//     return <Trans>The password must contain at least one uppercase letter (A-Z)</Trans>;
//   }

//   if (pass !== confirmPass) {
//     return <Trans>The Passwords don't match, please enter the same password on both fields.</Trans>;
//   }

//   return null;
// };

export const newPasswordValidator = {
  oneNumber: (value) =>
    oneNumber(String(value)) || (
      <Trans>The password must contain at least one number (0-9)</Trans>
    ),
  oneLowercase: (value) =>
    oneLowercase(String(value)) || (
      <Trans>
        The password must contain at least one lowercase letter (a-z)
      </Trans>
    ),
  oneUppercase: (value) =>
    oneUppercase(String(value)) || (
      <Trans>
        The password must contain at least one uppercase letter (A-Z)
      </Trans>
    ),
};

// export default passwordValidator;
