import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Bugsnag from "@bugsnag/js";
import { loginUserAction } from "~/redux/actions/auth_actions";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import validator from "validator";
import { useForm } from "react-hook-form";
import Input from "../input";
import InputError from "../inputError";
import SubmitSuccess from "./submitSuccess";
import LoginSocial from "./loginSocial";
import { login as loginUser } from "~/services/auth/login";
import ButtonStateful from "~/components/buttonStateful";
import Notification from "~/components/notification";
import navigate from "~/components/navigate";
import { wpURL } from "~/utils/app-helpers";
import Button from "~/components/button";

export const LoginForm = (props) => {
  const i18n = props.i18n;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const [errorMsg, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [loginSuccess, setLoginSuccess] = useState(props.loginSuccess);
  const [disableForm, setDisableForm] = useState(props.disableForm);

  const changeSection = props.changeSection;

  useEffect(() => {
    setError(props.errorMsg);
    setSuccessMsg(props.successMsg);

    return () => {
      return false;
    };
  }, [props.errorMsg, props.successMsg]);

  const handleFormLogin = async (body) => {
    setDisableForm(true);
    setError(null);
    setSuccessMsg(null);

    try {
      const { error, data, success } = await loginUser(body.email, body.password);

      if (error) {
        Bugsnag.notify(new Error(error.message));
        setError(error.message);
        setDisableForm(false);
        return false;
      }

      if (success) {
        setLoginSuccess(true);

        props.onLoginUser({
          email: data.email,
          full_name: data.full_name,
          courses: data.active_courses,
          coursesCounter: data.active_courses.length,
          avatar_url: data.avatar,
          is_tutor: data.is_tutor,
          stripe_id: data.stripe_id,
        });

        if (props.fromPage) {
          if (data.active_courses.length > 0) {
            // window.location.replace(
            //   `${wpURL(i18n.language)}/learner/my-purchased-courses/`
            // );
          } else {
            setTimeout(() => {
              navigate("/", i18n.language);
              return false;
            }, 1000);
          }
        } else {
          setTimeout(() => {
            props.hideModal();
          }, 2000);
        }
      } else {
        if (data.code === 1) {
          setError(<Trans>Wrong credentials.</Trans>);
        } else {
          if (data.code === 500) throw new Error(data.message);
          else setError(data.message);
        }
      }
    } catch (e) {
      Bugsnag.notify(e);
      setError(e.message);
    }

    setDisableForm(false);
  };

  return (
    <div className="text-center w-full">
      {loginSuccess ? (
        <SubmitSuccess>
          <Trans>Great, you are logged in.</Trans>
        </SubmitSuccess>
      ) : (
        <>
          <h2 className="mb-1">
            <Trans>Log in into your account</Trans>
          </h2>
          <div className="text-sm mb-2">
            <Trans>Please enter your details</Trans>
          </div>

          {successMsg && (
            <Notification type="success" className="text-center">
              {successMsg}
            </Notification>
          )}

          {errorMsg && (
            <Notification type="error" className="text-center">
              {errorMsg}
            </Notification>
          )}

          <form className="text-sm mt-3">
            <fieldset disabled={disableForm}>
              <div className="mb-3 md:mb-4">
                <Input
                  type="email"
                  placeholder={i18n._(t`Email address`)}
                  {...register("email", {
                    required: true,
                    validate: (value) => validator.isEmail(value) || i18n._(t`Invalid e-mail address`),
                  })}
                  autoComplete="email"
                  required
                  tabIndex={1}
                  error={typeof errors["email"] !== "undefined"}
                />
                <InputError errors={errors} field="email" />
              </div>
              <Input
                type="showHide"
                className="mb-3 md:mb-4"
                placeholder={i18n._(t`Password`)}
                {...register("password", {
                  required: true,
                })}
                autoComplete="current-password"
                required
                tabIndex={2}
                error={typeof errors["password"] !== "undefined"}
              />
            </fieldset>
            <div className="mb-3 md:mb-4 flex items-center justify-center">
              <input
                type="checkbox"
                id="rememberMe"
                name="rememberMe"
                value="true"
                disabled={disableForm}
                tabIndex={3}
                className="focus:ring-2 focus:ring-brand-primary focus:ring-opacity-50"
              />
              <label htmlFor="rememberMe" className="text-gray-700 text-xs ml-2">
                <Trans>Remember me</Trans>
              </label>
            </div>
            <div className="mb-3 md:mb-4">
              <ButtonStateful type="submit" loading={disableForm} className="h-12 text-lg" tabIndex={4} onClick={handleSubmit(handleFormLogin)}>
                <Trans>Log in</Trans>
              </ButtonStateful>
            </div>
          </form>
          <LoginFooter {...{ loginSuccess, disableForm, changeSection }} />
        </>
      )}
    </div>
  );
};

const LoginFooter = ({ loginSuccess, disableForm, changeSection }) => {
  const goTo = (section) => (e) => {
    e.preventDefault();
    changeSection(section);
  };

  return (
    <div className={loginSuccess ? "hidden" : ""}>
      <button onClick={goTo("forgot")} disabled={disableForm} className="text-sm text-brand-primary font-semibold hover:underline mt-3 mb-2">
        <Trans>Forgot your password?</Trans>
      </button>
      <div className="border-t border-gray-300 mt-4 mb-6" />
      <Button onClick={goTo("register")} disabled={disableForm} classType="reverse" className="h-12 text-lg font-semibold" tabIndex={4}>
        <Trans>Create an account</Trans>
      </Button>
      <div className="mb-3 flex justify-center text-sm"></div>
      <LoginSocial disableForm={disableForm} />
    </div>
  );
};

LoginForm.defaultProps = {
  disableForm: false,
  loginSuccess: false,
  successMsg: null,
  errorMsg: null,
  fromPage: false,
};

LoginForm.propTypes = {
  changeSection: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLoginUser: loginUserAction,
    },
    dispatch
  );
};

export default withI18n()(connect(null, mapActionsToProps)(LoginForm));
