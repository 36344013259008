export async function register(body) {
  let error = null;

  try {
    const response = await fetch(`${process.env.GATSBY_AUTH_URL}/register`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return await response.json();
  } catch (e) {
    error = e;
  }

  return { error };
}
