import React, { useState } from "react";
import Bugsnag from "@bugsnag/js";
import PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import validator from "validator";
import Input from "../input";
import InputError from "../inputError";
import LoginSocial from "./loginSocial";
import ButtonStateful from "~/components/buttonStateful";
import { useForm } from "react-hook-form";
import { register as registerUser } from "~/services/auth/register";
import Notification from "~/components/notification";
import SubmitSuccess from "~/components/auth/submitSuccess";
import { newPasswordValidator } from "~/components/passwordValidator";

const RegisterForm = (props) => {
  const i18n = props.i18n;
  const {
    register,
    handleSubmit,
    getValues,

    formState: {
      errors,
    },
  } = useForm({
    mode: "onBlur",
  });

  const [disableForm, setDisableForm] = useState(props.disableForm);
  const [errorMsg, setError] = useState(null);
  const [requested, setRequest] = useState(false);

  const onSubmit = async (body) => {
    setError(null);
    setDisableForm(true);

    try {
      const { error, success, data } = await registerUser(body);

      if (error) {
        throw new Error(error.message);
      }

      if (success) {
        setRequest(true);

        setTimeout(() => {
          props.changeSection("login");
        }, 2000);
      } else {
        switch (data.code) {
          case 1:
            setError(<Trans>Invalid e-mail address</Trans>);
            break;
          case 2:
            setError(<Trans>Email already registered</Trans>);
            break;
          default:
            throw new Error(data.message);
        }
      }
    } catch (e) {
      Bugsnag.notify(e);
      setError(
        <Trans>
          Something happened, please refresh the window and try again.
        </Trans>
      );
    }

    setDisableForm(false);
  };

  return (
    <div className="text-center w-full">
      {requested ? (
        <SubmitSuccess>
          <Trans>Register success, redirecting to login page...</Trans>
        </SubmitSuccess>
      ) : (
        <>
          <h2 className="mb-1">
            <Trans>Register your account</Trans>
          </h2>
          <div className="text-sm mb-8">
            <Trans>Please enter your details to get started</Trans>
          </div>
          {errorMsg && (
            <Notification type="error" className="text-center">
              {errorMsg}
            </Notification>
          )}
          <form className="text-sm">
            <fieldset disabled={disableForm}>
              <input type="hidden" value={i18n.language} {...register('lang')} />
              <div className="flex justify-between space-x-2 mb-3 md:mb-4">
                <div className="flex-1">
                  <Input
                    type="text"
                    placeholder={i18n._(t`First name`)}
                    {...register('firstName', {
                      required: true,
                      minLength: {
                        value: 2,
                        message: i18n._(t`The min value is 2`),
                      },
                    })}
                    required
                    error={typeof errors["firstName"] !== "undefined"} />
                  <InputError errors={errors} field="firstName" />
                </div>
                <div className="flex-1">
                  <Input
                    type="text"
                    placeholder={i18n._(t`Last name`)}
                    {...register('lastName', {
                      required: true,
                      minLength: {
                        value: 2,
                        message: i18n._(t`The min value is 2`),
                      },
                    })}
                    required
                    error={typeof errors["lastName"] !== "undefined"} />
                  <InputError errors={errors} field="lastName" />
                </div>
              </div>
              <div className="mb-3 md:mb-4">
                <Input
                  type="email"
                  placeholder={i18n._(t`Email address`)}
                  {...register('email', {
                    required: true,
                    validate: (value) =>
                      validator.isEmail(value) ||
                      i18n._(t`Invalid e-mail address`),
                  })}
                  required
                  autoComplete="email"
                  error={typeof errors["email"] !== "undefined"} />
                <InputError errors={errors} field="email" />
              </div>
              <div className="mb-3 md:mb-4">
                <Input
                  type="showHide"
                  placeholder={i18n._(t`Password`)}
                  {...register('password', {
                    required: true,
                    minLength: {
                      value: 8,
                      message: i18n._(t`The min value is 8`),
                    },
                    validate: newPasswordValidator,
                  })}
                  required
                  autoComplete="new-password"
                  error={typeof errors["password"] !== "undefined"} />
                <InputError errors={errors} field="password" />
              </div>
              <div className="mb-3 md:mb-4">
                <Input
                  type="showHide"
                  placeholder={i18n._(t`Confirm Password`)}
                  {...register('confirmPassword', {
                    validate: (value) =>
                      value === getValues().password ||
                      i18n._(t`Passwords do not match`),
                  })}
                  required
                  autoComplete="new-password"
                  error={typeof errors["confirmPassword"] !== "undefined"} />
                <InputError errors={errors} field="confirmPassword" />
              </div>
            </fieldset>
            <div className="mb-3 md:mb-4">
              <ButtonStateful
                type="submit"
                loading={disableForm}
                className="h-12 text-lg"
                onClick={handleSubmit(onSubmit)}
              >
                <Trans>Register</Trans>
              </ButtonStateful>
            </div>
            <div className="mb-3 md:mb-4 text-xs text-gray-400 text-center">
              <div className="md:inline-block">
                <Trans>
                  By clicking on "Register" I confirm that I am 16 years old or
                  older and that I accept the Terms and Conditions, the Cookie
                  Policy, the Privacy Policy and agree to receive free courses,
                  offers and educational content.
                </Trans>
              </div>
            </div>
          </form>
          <div className="mb-3">
            <button
              onClick={() => {
                props.changeSection("login");
              }}
              className="text-sm text-brand-primary font-semibold hover:underline"
            >
              <Trans>Back to login</Trans>
            </button>
          </div>
          <LoginSocial disableForm={disableForm} />
        </>
      )}
    </div>
  );
};

RegisterForm.defaultProps = {
  disableForm: false,
};

RegisterForm.propTypes = {
  changeSection: PropTypes.func.isRequired,
};

export default withI18n()(RegisterForm);
