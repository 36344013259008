import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";
import { withI18n } from "@lingui/react";
import { localizedSlug } from "../utils/gatsby-node-helpers";

const Link = ({ to, children, i18n, style, className, title }) => {
  if (to.match(/^https?:\/\//)) {
    return (
      <a href={to} {...{ style, className, title }}>
        {children}
      </a>
    );
  }

  const lng = i18n.language;

  const path = localizedSlug({
    isDefault: lng === "en" ? true : false,
    locale: lng,
    slug: to,
  });

  return (
    <GatsbyLink to={path} {...{ style, className, title }}>
      {children}
    </GatsbyLink>
  );
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default withI18n({ withHash: false })(Link);
