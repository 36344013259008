// Use a little helper function to remove trailing slashes from paths
exports.removeTrailingSlash = (path) =>
  path === `/` ? path : path.replace(/\/$/, ``);

exports.localizedSlug = ({ isDefault, locale, slug }) => {
  if (isDefault) return slug === `/` ? `/` : `${slug}`;

  let path, splitedPath;

  splitedPath = slug.split(`/`); //dividir por "/"

  //filtrar valores vacios en el array
  let filtered = splitedPath.filter(function (el) {
    return el;
  });

  const filters = require("../locales/es/filters.json");

  if (filtered.length > 0) {
    switch (filtered[0]) {
      case "course":
        filtered[0] = "curso";
        break;
      case "courses":
        filtered[0] = "cursos";
        break;
      case "terms":
        filtered[0] = "terminos";
        break;
      case "privacy-policy":
        filtered[0] = "politicas-de-privacidad";
        break;
      case "cookie-policy":
        filtered[0] = "politicas-de-cookies";
        break;
      case "about-us":
        filtered[0] = "acerca-de-nosotros";
        break;
      case "public-profile":
        filtered[0] = "perfil-publico";
        break;
      default:
    }

    if (
      typeof filtered[1] != "undefined" &&
      typeof filters[filtered[1]] !== "undefined"
    ) {
      filtered[1] = filters[filtered[1]];
    }
  }

  path = filtered.join("/");

  return `/${locale}/${path}`;
};

// From lodash:
// https://github.com/lodash/lodash/blob/750067f42d3aa5f927604ece2c6df0ff2b2e9d72/findKey.js
exports.findKey = (object, predicate) => {
  let result;
  if (object == null) {
    return result;
  }
  Object.keys(object).some((key) => {
    const value = object[key];
    if (predicate(value, key, object)) {
      result = key;
      return true;
    }
    return false;
  });
  return result;
};
