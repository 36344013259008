import React from "react";
import sanitizeHtml from "sanitize-html";

const options = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat([
    "img",
    "span",
    "p",
    "strong",
    "ul",
    "ol",
    "li",
    "em",
  ]),
  allowedAttributes: {
    //   "*": ["style"],
    img: ["src", "srcset", "alt"],
  },
  transformTags: {
    a: "span",
  },
  exclusiveFilter: function (frame) {
    return frame.tag === "p" && !frame.text.trim();
  },
};

export function sanitize(html) {
  return sanitizeHtml(html, options);
}

const Sanitize = ({ html, className, tag = "span" }) => {
  const clean = sanitizeHtml(html, options);

  const component = React.createElement(tag, {
    className: `sanitized-html ${className}`,
    dangerouslySetInnerHTML: { __html: clean },
  });

  return component;
};

export default Sanitize;
