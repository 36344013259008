import React, { useState } from "react";
import ModalWrapper from "./modalWrapper";
import Instant from "./search/instant";

const ModalSearch = () => {
  const [show, setVisibility] = useState(false);

  return (
    <>
      {show && (
        <ModalWrapper
          hideModal={setVisibility}
          className="p-0 rounded-full bg-current"
          width="90%"
        >
          <Instant className="" />
        </ModalWrapper>
      )}
      <button onClick={() => setVisibility(true)}>
        <span className="icon-search text-gray-400" />
      </button>
    </>
  );
};

export default ModalSearch;
