import React from "react";

const ClockSpinner = (props) => {
  return (
    <div className="relative inline-block mr-1">
      <img src="/images/puff.svg" alt="" className="w-6 h-6" />
    </div>
  );
};

export default ClockSpinner;
