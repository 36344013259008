import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Trans } from "@lingui/macro";
import ClockSpinner from "./clockSpinner";

export default function ButtonStateful(props) {
  let { type, className, loading, children, ...rest } = { ...props };

  let classes = classNames(className, "btn w-full whitespace-nowrap", {
    "btn-primary": !loading,
    "btn-secondary-2 hover:bg-brand-gray text-gray-600": loading,
  });

  return (
    <button type={type} className={classes} disabled={loading} {...rest}>
      {loading ? (
        <span className="flex items-center justify-center">
          <ClockSpinner /> <Trans>Please wait...</Trans>
        </span>
      ) : (
        children
      )}
    </button>
  );
}

ButtonStateful.defaultProps = {
  type: "button",
};

ButtonStateful.propTypes = {
  children: PropTypes.element.isRequired,
  loading: PropTypes.bool.isRequired,
};
