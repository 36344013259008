import React from "react";
import { connectHits, connectStateResults } from "react-instantsearch-dom";
import { Trans } from "@lingui/macro";
import Link from "../link";
import Sanitize from "../sanitize";

const Hits = ({ hits, query }) => {
  return (
    <div className="dropdown mt-1 md:mt-4">
      <div className="overflow-auto">
        <ol className="md:pt-1 md:px-3">
          {hits.length ? (
            hits.map((hit) => (
              <li key={hit.objectID}>
                <Link
                  to={`/course/${hit.permalink.category}/${hit.permalink.slug}`}
                  className="text-gray-800 font-medium hover:text-gray-600 no-underline"
                >
                  <div className="flex items-center text-base leading-snug mb-4 lg:p-2 lg:mb-0">
                    <span className="icon-search hidden lg:inline-block" />
                    <div className="md:pl-3">
                      <div className="text-xs md:text-sm">
                        <Sanitize html={hit.title} />
                      </div>
                      <div className="text-gray-400 text-xs md:text-sm font-normal"></div>
                    </div>
                  </div>
                </Link>
              </li>
            ))
          ) : (
            <li>
              <Trans>No results for query</Trans> "{query}"
            </li>
          )}
        </ol>
      </div>
    </div>
  );
};

const CustomHits = connectHits(Hits);

const Content = connectStateResults(({ searchState }) => {
  return (
    <>
      {searchState && searchState.query && (
        <CustomHits query={searchState.query} />
      )}
    </>
  );
});

export default Content;
